@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Pretendard";
  src: local("Pretendard ExtraLight"),
    url("/fonts/woff2/Pretendard-ExtraLight.woff2") format("woff2");
  font-display: swap;
  font-weight: 200;
  font-style: normal;
}
@font-face {
  font-family: "Pretendard";
  src: local("Pretendard Light"),
    url("/fonts/woff2/Pretendard-Light.woff2") format("woff2");
  font-display: swap;
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "Pretendard";
  src: local("Pretendard Regular"),
    url("/fonts/woff2/Pretendard-Regular.woff2") format("woff2");
  font-display: swap;
  font-weight: 400;
  font-style: normal;
}
/* Pretendard SemiBold 추가 */ /* <-- 추가된 부분 */
@font-face {
  font-family: "Pretendard";
  src: local("Pretendard Medium"),
    url("/fonts/woff2/Pretendard-Medium.woff2") format("woff2"); /* <-- 파일 경로는 확인 후 수정해 주세요 */
  font-display: swap;
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "Pretendard";
  src: local("Pretendard SemiBold"),
    url("/fonts/woff2/Pretendard-SemiBold.woff2") format("woff2"); /* <-- 파일 경로는 확인 후 수정해 주세요 */
  font-display: swap;
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: "Pretendard";
  src: local("Pretendard Bold"),
    url("/fonts/woff2/Pretendard-Bold.woff2") format("woff2");
  font-display: swap;
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Pretendard";
  src: local("Pretendard ExtraBold"),
    url("/fonts/woff2/Pretendard-ExtraBold.woff2") format("woff2");
  font-display: swap;
  font-weight: 800;
  font-style: normal;
}

:root {
  --swiper-pagination-bottom: 15px;
  --swiper-pagination-bullet-horizontal-gap: 2px;
  --swiper-wrapper-transition-timing-function: ease-in-out;

  --blue-rgba: rgba(1, 152, 255, 0.05);
  --gray-rgba: rgba(17, 17, 17, 0.5);
  --light-gray-rgba: rgba(0, 0, 0, 0.2);
  --mapBg: #afd5f0;
  --chart1: #3366e6;
  --chart2: #ff6633;
  --chart3: #ff33ff;
  --chart4: #ffff99;
  --chart5: #00b3e6;
  --chart6: #e6b333;
  --chart7: #ffb399;
  --chart8: #999966;
  --chart9: #99ff99;
  --chart10: #b34d4d;
  --neutral-black: #000;
  --neutral-white: #fff;
  --gray2: #f6f7f9;
  --gray5: #eff2f5;
  --gray10: #e9edf1;
  --gray15: #e3e8ee;
  --gray20: #dae1e9;
  --gray30: #bdc7d1;
  --gray40: #9fadbc;
  --gray50: #8194a7;
  --gray60: #647a90;
  --gray70: #4f6272;
  --gray80: #3b4754;
  --gray90: #262e36;

  --blue5: #edf5ff;
  --blue10: #d0e2ff;
  --blue20: #a6c8ff;
  --blue30: #78a9ff;
  --blue40: #4589ff;
  --blue50: #0f62fe;
  --blue60: #0043ce;
  --blue70: #002d9c;
  --blue80: #001d6c;
  --blue90: #001141;

  --red5: #fff1f1;
  --red10: #ffd7d9;
  --red20: #ffb3b8;
  --red30: #ff8389;
  --red40: #fa4d56;
  --red50: #da1e28;
  --red60: #a2191f;
  --red70: #750e13;
  --red80: #520408;
  --red90: #2d0709;

  --risk-green: #34bb7a;
  --risk-yellow: #f99f01;
  --risk-orange: #ff4d00;
  --risk-red: #df1525;

  --risk-bg-green: #e6f5f0;
  --risk-bg-yellow: #fef5e6;
  --risk-bg-orange: #ffe4d9;
  --risk-bg-red: #ffecef;
  --price-forecast-table-width: 100px;
  --high-score-stock-table-width: 100px;
  --vh: 100%;
}
* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  font-family: "Pretendard";
  text-overflow: ellipsis;
}

a {
  color: inherit;
  text-decoration: none;
}

body {
  /* @apply bg-gray5;/ */
  background: var(--gray5);
  font-family: "Pretendard";
  /* height: var(--vh);
  min-height: 100dvh; */
  min-height: -webkit-fill-available;
  /* scrollbar-gutter: stable; */
}
div {
  user-select: none; /* 텍스트 선택 방지 */
  -webkit-user-select: none; /* iOS 및 Chrome */
}

h1,
h2,
h3,
h4,
h5 {
  font-weight: 700;
}
h6 {
  font-weight: 600;
}
th {
  font-weight: 400;
}

.customScrollBar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: thin; /* Firefox */
}

.customScrollBar::-webkit-scrollbar {
  display: none !important;
}

.slim-scroll::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
button {
  cursor: auto;
}
.slim-scroll::-webkit-scrollbar-thumb {
  background-color: #e4e4e4;
  border-radius: 10px;
  background-clip: padding-box;
  border: 1px solid transparent;
  width: 3px;
  height: 3px;
}
.slim-scroll::-webkit-scrollbar-track {
  background-color: rgb(247, 247, 247);
  border-radius: 4px;
  width: 3px;
  height: 3px;
}

.pf-scroll::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}
.pf-scroll::-webkit-scrollbar-thumb {
  background-color: #cccccc;
  border-radius: 10px;
  background-clip: padding-box;
  border: 1px solid transparent;
}
.pf-scroll::-webkit-scrollbar-track {
  background-color: #ececec;
  border-radius: 10px;
}

.floating-button {
  display: flex;
  position: fixed;
  right: 10px;
  bottom: 15px;
  z-index: 999;
}

.gradient-cover {
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0), white);
}

.ai-analysis,
.scroll {
  -ms-overflow-style: none; /* IE, Edge */
  scrollbar-width: none; /* Firefox */
}

.ai-analysis::-webkit-scrollbar,
.scroll::-webkit-scrollbar {
  display: none; /* Chrome, Safari, Opera */
}

.box-input::placeholder {
  color: var(--gray50);
}
.box-input:focus,
.error-input:focus {
  background-color: var(--gray10);
  outline: none;
}

.box-input:focus {
  border: 1px solid var(--gray20);
}

.error-box-input {
  border: 1px solid var(--red40) !important;
}
.error-line-input {
  border-bottom: 1px solid var(--red40) !important;
}

.line-input:focus {
  outline: none;
  border-bottom-color: var(--blue40);
}

.pf-input {
  outline: none;
}
@keyframes slideUp {
  from {
    transform: translate(-50%, 100%);
  }
  to {
    transform: translate(-50%, 0);
  }
}
@keyframes MslideUp {
  from {
    transform: translate(0%, 100%);
  }
  to {
    transform: translate(0%, 0);
  }
}

.modal-entering {
  animation: slideUp 0.5s ease forwards;
}

.m-modal-entering {
  animation: MslideUp 0.5s ease forwards;
}
.detail-toggle {
  display: block;
}
.detail-toggle.toggle-open {
  display: flex;
  flex-direction: column;
}

.detail-toggle.toggle-open .detail-content {
  max-height: 800px; /* 최대 높이 값을 조정해야 할 수 있습니다. */
  transition: max-height 0.6s 0s ease-in-out;
  /* overflow: visible; */
  overflow: hidden;
  /* overflow: inherit; */
  /* opacity: 1; */
}
.detail-content {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.2s 0s ease-in-out;
}
.strategy-recommand-toggle.toggle-open .strategy-recommand-content {
  max-height: 300px; /* 최대 높이 값을 조정해야 할 수 있습니다. */
  transition: max-height 0.6s 0s ease-in-out;
  /* overflow: visible; */
  overflow: hidden;
  /* overflow: inherit; */
  /* opacity: 1; */
}
.strategy-recommand-content {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.4s 0s ease-in-out;
}

.expanded {
  max-height: 1000px; /* 최대 높이 값을 조정해야 할 수 있습니다. */
  transition: all 0.5s ease-in;
  overflow: hidden;
  opacity: 1;
}

.collapsed {
  max-height: 0;
  opacity: 0;
  overflow: hidden;
}

.alarm-expanded {
  max-height: 1000px; /* 최대 높이 값을 조정해야 할 수 있습니다. */
  transition: max-height 0.3s ease-in, opacity 0.3s ease-in;
  overflow: hidden;
  opacity: 1;
  padding-top: 12px;
  margin-top: 12px;
}

.alarm-collapsed {
  max-height: 0;
  opacity: 0;
  overflow: hidden;
  padding-top: 0px;
}

.price-forecast-table,
.daily-gain-table,
.high-score-stock-table {
  border-collapse: collapse; /* 테두리 겹침을 방지하여 더 깔끔한 외관을 제공 */
  width: 100%; /* 테이블 너비 설정 */
}

.price-forecast-table {
  table-layout: fixed;
}
.price-forecast-table th,
.price-forecast-table td {
  border-top: 1px solid var(--gray20);
  border-bottom: 1px solid var(--gray20);
  padding: 4px 16px;
  text-align: right;
  white-space: nowrap;
  width: 94px;
}
.price-forecast-table th {
  border-top: 0px;
  height: 40px;
}
.price-forecast-table td {
  height: 50px;
}
/* 첫 번째 th와 td 스타일 */
.price-forecast-table th:first-child,
.price-forecast-table td:first-child {
  text-align: left;
  width: 100px;
  background-color: white;
}
.price-forecast-table tr:last-child td {
  border-bottom: 0px;
}

.high-score-stock-table {
  /* table-layout: fixed; */
  table-layout: auto;
}
.high-score-stock-table th {
  /* border-top: 1px solid var(--gray20); */
  border-bottom: 1px solid var(--gray20);
  padding: 16px;
  text-align: right;
  white-space: nowrap;
  height: 40px;
}
.high-score-stock-table td {
  /* border-top: 1px solid var(--gray20); */
  border-bottom: 1px solid var(--gray20);
  padding: 20px 16px;
  text-align: right;
  white-space: nowrap;
  height: 60px;
  /* max-height: 64px; */
  /* width: 150px; */
}

.sticky-column {
  position: sticky;
  left: 0; /* 왼쪽에 고정 */
  background-color: #ffffff;
  z-index: 16; /* 다른 열 위로 쌓이도록 설정 */
}
/* .scroll-table::before {
  box-shadow: inset 10px 0 16px -8px #00000026;
  position: absolute;
  top: 0;
  right: 0;
  bottom: -1px;
  width: 30px;
  transform: translate(100%);
  transition: box-shadow 0.3s;
  content: "";
  pointer-events: none;
} */

.high-score-stock-table th:first-child,
.high-score-stock-table td:first-child {
  text-align: left;
  width: var(--high-score-stock-table-width);
  /* background-color: white; */
  /* padding-left: 24px;
  padding-right: 16px; */
}
.high-score-stock-table tr:last-child td {
  border-bottom: 0px;
}

/* 마지막 th와 td 스타일 */
/* .price-forecast-table th:last-child,
.price-forecast-table td:last-child {
  padding-right: 24px;
} */

.daily-gain-table th {
  border-top: 1px solid var(--gray20);
  border-bottom: 1px solid var(--gray20);
  padding: 10px 8px;
  background-color: var(--gray5);
  text-align: left;
}

.daily-gain-table td {
  border-top: 1px solid var(--gray20);
  border-bottom: 1px solid var(--gray20);
  text-align: center;
  padding: 10px 8px;
}

@keyframes slideAndFade {
  from {
    transform: translateY(20px); /* 시작 위치 조정 */
    opacity: 0; /* 시작 투명도 */
  }
  to {
    transform: translateY(0); /* 최종 위치 */
    opacity: 1; /* 최종 투명도 */
  }
}

.text-fade-in {
  animation-name: slideAndFade; /* 애니메이션 이름 지정 */
  animation-duration: 0.6s; /* 애니메이션 지속 시간 */
  animation-delay: 0.3s;
  opacity: 0;
  animation-timing-function: ease-out; /* 가속도 곡선 */
  animation-fill-mode: forwards; /* 애니메이션 종료 후 최종 상태 유지 */
  animation-iteration-count: 1; /* 애니메이션 반복 횟수 (1번만 실행) */
}

@keyframes topToastFadeIn {
  from {
    opacity: 0;
    top: 0px;
  }
  to {
    opacity: 1;
    top: 20px;
  }
}
@keyframes topToastFadeInDesktop {
  from {
    opacity: 0;
    top: 70px;
  }
  to {
    opacity: 1;
    top: 90px;
  }
}

.top-toast {
  animation: topToastFadeIn 0.5s ease-in-out;
  animation-fill-mode: forwards;
}
.top-desktop-toast {
  animation: topToastFadeInDesktop 0.5s ease-in-out;
  animation-fill-mode: forwards;
}

@keyframes bottomToastFadeIn {
  from {
    opacity: 0;
    bottom: 20px;
  }
  to {
    opacity: 1;
    bottom: 40px;
  }
}
@keyframes bottomToastFadeIn2 {
  from {
    opacity: 0;
    bottom: 40px;
  }
  to {
    opacity: 1;
    bottom: 110px;
  }
}

.bottom-toast {
  animation: bottomToastFadeIn 0.5s ease-in-out;
  animation-fill-mode: forwards;
}
.bottom-toast2 {
  animation: bottomToastFadeIn2 0.5s ease-in-out;
  animation-fill-mode: forwards;
}

@keyframes fadeOutAnimation {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
.fade-out {
  animation: fadeOutAnimation 0.6s;
}
.fade-out-strategy {
  animation: fadeOutAnimation 0.2s ease-in-out;
}
@keyframes fadeInAnimation {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
.fade-in-strategy {
  animation: fadeInAnimation 0.4s ease-in-out;
  /* animation-delay: 0.1s; */
}
@keyframes strategy-scale-out {
  from {
    transform: scale(1);
    opacity: 1;
    width: 100%;
  }
  to {
    transform: scale(0.8);
    opacity: 0;
    width: 0px;
  }
}
.fade-out-strategy-scale-out {
  animation: strategy-scale-out 0.6s ease-in-out;
}
@keyframes strategy-scale-in {
  from {
    transform: scale(0.8);
    opacity: 0;
    width: 0px;
  }
  to {
    transform: scale(1);
    opacity: 1;
    width: 100%;
  }
}
.fade-in-strategy-scale-in {
  animation: strategy-scale-in 0.6s ease-in-out;
}

@keyframes fadeOutAnimation {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
.entire-fade-out {
  animation: fadeOutAnimation;
  animation-duration: 0.6s;
  animation-delay: 0.8s;
  opacity: 1;
  animation-fill-mode: forwards; /* 애니메이션 종료 후 최종 상태 유지 */
}

.time-table {
  border-collapse: collapse;
  width: 100%;
}
.time-table th,
.time-table td {
  border: 1px solid var(--gray10);
  padding: 6px 0;
  text-align: center;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.alarm-complete {
  animation: fadeIn 0.4s ease-in-out;
  animation-fill-mode: forwards;
}
.industry-header {
  animation: fadeIn 0.3s ease-in-out;
  animation-fill-mode: forwards;
}

.policy-table {
  border-collapse: collapse;
  width: 100%;
}
.policy-table th,
.policy-table td {
  border: 1px solid var(--gray10);
  padding: 6px 10px;
  text-align: center;
}
.pfname-expanded {
  max-height: 1000px; /* 최대 높이 값을 조정해야 할 수 있습니다. */
  transition: all 0.5s ease-in;
  overflow: hidden;
}

.pfname-collapsed {
  max-height: 32px;
  overflow: hidden;
}

.centered-custom-width-mobile {
  width: calc(100% - 48px) !important;
  margin-left: auto !important;
  margin-right: auto !important;
}
.centered-custom-width-desktop {
  width: calc(800px - 48px) !important;
  margin-left: auto !important;
  margin-right: auto !important;
}

@keyframes shakeAnimation {
  0% {
    -webkit-transform: translate(10px);
  }
  20% {
    -webkit-transform: translate(-10px);
  }
  40% {
    -webkit-transform: translate(5px);
  }
  60% {
    -webkit-transform: translate(-5px);
  }
  80% {
    -webkit-transform: translate(3px);
  }
  100% {
    -webkit-transform: translate(0px);
  }
}

.shake {
  animation: shakeAnimation 0.3s 1 linear;
  -webkit-animation: shakeAnimation 0.3s 1 linear;
  -moz-animation: shakeAnimation 0.3s 1 linear;
  -o-animation: shakeAnimation 0.3s 1 linear;
}

/* .highcharts-axis-line {
} */
.highcharts-yaxis .highcharts-axis-line {
  display: none;
}

.highcharts-xaxis {
  stroke-dasharray: 5, 5;
}
#detail-candle .highcharts-axis-line {
  stroke-dasharray: 0;
}
#detail-candle .highcharts-plot-line-label {
  transform: translate(100%, 0) !important;
}

/* #detail-candle .highcharts-tooltip-box:first-child {
  fill: black !important;
  fill-opacity: 0.5 !important;
  stroke-width: 0;
} */
#price-forecast-highchart .highcharts-xaxis {
  display: none;
}

#main-noti .swiper-pagination-bullet,
#sales .swiper-pagination-bullet {
  width: 5px !important;
  height: 5px !important;
  background-color: var(--gray30) !important;
  opacity: 1 !important;
  transition: width 0.3s ease !important;
}

#main-noti .swiper-pagination-bullet-active,
#sales .swiper-pagination-bullet-active {
  width: 20px !important;
  border-radius: 15px !important;
  background-color: var(--gray40) !important;
}
#main-noti .swiper-wrapper {
  padding-top: 24px !important;
  padding-bottom: 41px !important;
  position: relative !important;
}
#main-noti .swiper-initialized {
  position: relative !important;
}

#main-noti .swiper-slide-active {
  opacity: 1 !important;
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
#main-noti .swiper-slide-prev {
  animation: fadeOut 1s ease-in-out !important;
}
#sales .swiper-slide-prev {
  animation: fadeOut 0.6s ease-in-out !important;
}

.widget-box-shadow {
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.08) !important;
  -webkit-box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.08) !important;
}

@keyframes infiniteAnimation1 {
  0% {
    transform: translateX(0%);
  }
  50% {
    transform: translateX(-100%);
  }
  51% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0%);
  }
}

@keyframes infiniteAnimation2 {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-200%);
  }
}

.original {
  animation: 70s linear infinite normal none running infiniteAnimation1;
}
.clone {
  animation: 70s linear infinite infiniteAnimation2;
}

#sales-widget-chart .highcharts-xaxis {
  display: none;
}

/* .highcharts-crosshair-label {
  z-index: 0 !important;
} */
@media (max-width: 440px) {
  #detail-candle .ohlc-tooltip {
    min-width: 280px;
    flex-wrap: wrap;
  }
}
#detail-candle {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-use-select: none;
  user-select: none;
}
.no-drag {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-use-select: none;
  user-select: none;
}
/* .highcharts-crosshair {
  pointer-events: none;
} */

#detail-candle .highcharts-plot-band,
#detail-candle .highcharts-plot-bands-0 {
  pointer-events: none;
}

/* 커서의 기본 스타일 */
.cursor {
  position: absolute;
  width: 3px; /* 커서의 너비 */
  height: 20px; /* 텍스트 높이에 맞춘 커서 높이 */
  background-color: var(--blue40); /* 커서 색상 */
  top: 50%;
  transform: translateY(-50%);
  animation: blink 1s step-end infinite;
  /* step-end를 사용하여 부드러운 fadeout이 아닌 깜빡임 효과 구현 */
}

@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.cursor-hidden {
  display: none;
}

.kb-password-inputer::placeholder {
  color: var(--gray20) !important;
}
.kb-password-inputer {
  -webkit-text-security: disc;
  font-size: inherit;
  font-family: inherit;
  font: small-caption;
  letter-spacing: 7px;
  -webkit-text-size-adjust: none !important;
}
.kb-password-inputer:focus {
  outline: none;
}

.safe-bottom {
  bottom: env(safe-area-inset-bottom);
  bottom: constant(safe-area-inset-bottom);
}

.react-pdf__Page__annotations {
  display: none !important;
}
.rpv-core__inner-container .rpv-toolbar__left,
.rpv-core__inner-container .rpv-toolbar__right {
  display: none !important;
}

/**
font-size: 0px; /* $reel-font-size */

/* CSS 변환 버전 */
@keyframes reel-loop {
  from {
    margin-top: 0px;
  }
  to {
    margin-top: -300px; /* 30px * 8 * (-1) */
  }
}

@keyframes reel-begin {
  0% {
    margin-top: 0px;
  }
  75% {
    margin-top: -30px; /* $reel-font-size * (-1) */
  }
  100% {
    margin-top: 20px; /* $reel-font-size / 3 */
  }
}

@keyframes reel-stop {
  from {
    top: -50px;
  }
  to {
    top: 0px;
  }
}

/* 슬롯을 적절한 위치에 배치하기 위한 스타일 */
.main-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.reel {
  /* width: 70px; $reel-width */
  height: 38px; /* $reel-height */
  float: left;
  position: relative;
  overflow: hidden;
}

.reel-symbol {
  vertical-align: middle;
  text-align: center;
  overflow: hidden;
}

.reel-container {
  /* height: 90px; $reel-height */
  overflow: hidden;
  display: flex;
  flex-direction: row;
}

.reel-loop {
  animation-duration: 1.8s;
  animation-name: reel-loop;
  animation-iteration-count: infinite;
  /* animation-timing-function: cubic-bezier(0.7, -0.4, 0.4, 1.4); */
  /* animation-timing-function: linear; */
  animation-timing-function: ease-in-out;
  /* animation-timing-function: ease-in; */
  /* animation-timing-function: ease-out; */
  animation-direction: reverse;
}

.reel-stop {
  animation-duration: 0.1s;
  animation-name: reel-stop;
  animation-timing-function: ease-out;
  animation-delay: 0.2s;
}

/* .reel-begin {
  animation-duration: 0.35s;
  animation-name: reel-begin;
  animation-timing-function: linear;
}
 */
